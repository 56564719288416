import { DataStream, RadioNetworkDetails } from '@/entities/network/types';
import { request, ResponseData, PaginatedWithFoffset } from '@/shared/api';
import { V2_PREFIX, V3_PREFIX } from '@/shared/config';
import { DEFAULT_PAGE_SIZE } from '@/shared/constants';
import { Network, NetworkFilters, NetworkV2, RadioNetworkItem, RadioNetworkV2 } from '@/shared/types';
import {
  CreateNetworkPayload,
  UpdateNetworkParams,
  AddToNetworkPayload,
  UpdateDataStreamParams,
  DeleteDataStreamParams,
} from './types';
import {
  getNetworksSchema,
  createNetworkSchema,
  updateNetworkSchema,
  addToNetworkSchema,
  getRadioNetworksSchema,
  getRadioNetworksFilterSchema,
  getRadioNetworkByIdSchema,
  getRadioNetworkDetailsSchema,
  updateDataStreamSchema,
  updateNetworkSchemaV2,
  addToNetworkSchemaV2,
  createNetworkSchemaV2,
} from './validationSchemas';

export const getRadioNetworksFilter = () =>
  request<ResponseData<NetworkFilters>>('/radio-networks/filter', {
    endpointPrefix: V2_PREFIX,
    validationSchema: getRadioNetworksFilterSchema,
  });

export const getRadioNetworks = ({ currentPage, queryParams }: { currentPage: number; queryParams: string }) => {
  const params = new URLSearchParams([
    ['page', String(currentPage)],
    ['size', DEFAULT_PAGE_SIZE],
  ]).toString();

  let url = `/radio-networks?${params}`;
  if (queryParams) {
    url += `&${queryParams}`;
  }

  return request<PaginatedWithFoffset<RadioNetworkV2>>(url, {
    endpointPrefix: V2_PREFIX,
    validationSchema: getRadioNetworksSchema,
  });
};

export const getRadioNetworkById = (id: string) =>
  request<ResponseData<RadioNetworkItem>>(`/radio-networks/${id}`, {
    endpointPrefix: V3_PREFIX,
    validationSchema: getRadioNetworkByIdSchema,
  });

export const getRadioNetworkDetails = (networkId: string) =>
  request<ResponseData<RadioNetworkDetails>>(`/radio-networks/${networkId}/details`, {
    endpointPrefix: V3_PREFIX,
    validationSchema: getRadioNetworkDetailsSchema,
  });

export const updateDataStream = ({ networkId, dataStreamId, payload }: UpdateDataStreamParams) =>
  request<ResponseData<DataStream>>(`/radio-networks/${networkId}/data-stream/${dataStreamId}`, {
    method: 'PATCH',
    body: JSON.stringify(payload),
    endpointPrefix: V3_PREFIX,
    validationSchema: updateDataStreamSchema,
  });

export const deleteDataStream = ({ networkId, dataStreamId }: DeleteDataStreamParams) =>
  request(`/radio-networks/${networkId}/data-stream/${dataStreamId}`, {
    method: 'DELETE',
    endpointPrefix: V3_PREFIX,
  });

export const getNetworks = (name?: string) => {
  const params = new URLSearchParams();
  params.set('s', name || '');
  return request<ResponseData<NetworkV2[]>>(`/radio-networks/network?${name ? params.toString() : ''}`, {
    endpointPrefix: V2_PREFIX,
    validationSchema: getNetworksSchema,
  });
};

export const createNetwork = (payload: CreateNetworkPayload) =>
  request<ResponseData<Network>>(`/radio-networks`, {
    method: 'POST',
    body: JSON.stringify(payload),
    endpointPrefix: V3_PREFIX,
    validationSchema: createNetworkSchema,
  });

export const updateNetwork = ({ networkId, payload }: UpdateNetworkParams) =>
  request<ResponseData<Network>>(`/radio-networks/${networkId}`, {
    method: 'PATCH',
    body: JSON.stringify(payload),
    endpointPrefix: V3_PREFIX,
    validationSchema: updateNetworkSchema,
  });

export const addToNetwork = ({ networkId, frequency }: AddToNetworkPayload) =>
  request<ResponseData<Network>>(`/radio-networks/${networkId}/frequency`, {
    method: 'POST',
    body: JSON.stringify({ frequency }),
    endpointPrefix: V3_PREFIX,
    validationSchema: addToNetworkSchema,
  });

// TODO Remove this code in 2nd iteration
export const createNetworkV2 = (payload: CreateNetworkPayload) =>
  request<NetworkV2>(`/radio-networks`, {
    method: 'POST',
    body: JSON.stringify(payload),
    endpointPrefix: V2_PREFIX,
    validationSchema: createNetworkSchemaV2,
  });

export const updateNetworkV2 = ({ networkId, payload }: UpdateNetworkParams) =>
  request<NetworkV2>(`/radio-networks/${networkId}`, {
    method: 'PUT',
    body: JSON.stringify(payload),
    endpointPrefix: V2_PREFIX,
    validationSchema: updateNetworkSchemaV2,
  });

export const addToNetworkV2 = ({ networkId, frequency }: AddToNetworkPayload) =>
  request<NetworkV2>(`/radio-networks/${networkId}/frequency`, {
    method: 'POST',
    body: JSON.stringify({ frequency }),
    endpointPrefix: V2_PREFIX,
    validationSchema: addToNetworkSchemaV2,
  });
