import { useEffect, useState } from 'react';
import pick from 'lodash/pick';
import { useIsFrequencyDragging } from '@/entities/frequency';
import {
  addFrequencyToFrequency,
  selectIsCreateNetworkModalOpened,
  selectIsNetworkListUpdating,
  setIsNetworkListUpdating,
  setScrollToNetworkId,
  useSynchronizeNetwork,
  useAddToNetwork,
} from '@/entities/network';
import { RadioNetworkType } from '@/shared/constants';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { RadioNetwork, Frequency } from '@/shared/types';
import { useDragWrapperComponent } from './useDragWrapperComponent';

export const useDraggableListItem = (item: RadioNetwork) => {
  const isNetworkListUpdating = useAppSelector(selectIsNetworkListUpdating);
  const isCreateNetworkModalOpened = useAppSelector(selectIsCreateNetworkModalOpened);
  const { synchronizeNetwork } = useSynchronizeNetwork();
  const isFrequencyDragging = useIsFrequencyDragging();
  const [networkKey, setNetworkKey] = useState<number>(1);
  const WrapperComponent = useDragWrapperComponent(item);
  const dispatch = useAppDispatch();
  const changeNetworkKey = () => setNetworkKey((prevKey) => prevKey + 1);

  const scrollToUpdatedNetwork = (data: RadioNetwork) => {
    synchronizeNetwork(data);
    dispatch(setScrollToNetworkId(data.id));
    dispatch(setIsNetworkListUpdating(false));
  };

  const { addToNetwork } = useAddToNetwork({
    onSuccess: scrollToUpdatedNetwork,
    onError: () => {
      changeNetworkKey();
      dispatch(setIsNetworkListUpdating(false));
    },
  });

  const handleDragEnd = (droppableItem: RadioNetwork | null, draggableItem: Frequency) => {
    // add frequency to network
    if (droppableItem && droppableItem.type === RadioNetworkType.NETWORK) {
      dispatch(setIsNetworkListUpdating(true));
      addToNetwork({ networkId: droppableItem.id, frequency: draggableItem.id });
    } else if (droppableItem && droppableItem.type === RadioNetworkType.FREQUENCY) {
      // create network from two frequencies
      dispatch(
        addFrequencyToFrequency([
          pick(droppableItem, ['id', 'value', 'sourceName']),
          pick(draggableItem, ['id', 'value', 'sourceName']),
        ])
      );
    }
  };

  useEffect(() => {
    if (!isCreateNetworkModalOpened) {
      changeNetworkKey();
    }
  }, [isCreateNetworkModalOpened]);

  return {
    WrapperComponent,
    handleDragEnd,
    networkKey,
    isFrequencyDragging,
    isNetworkListUpdating,
  };
};
