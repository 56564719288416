import { LABEL_SEPARATOR } from '@/shared/constants';
import { Frequency } from '@/shared/types';

interface Params {
  name: Frequency['value'];
  sourceName: Frequency['sourceName'];
}

const getFrequencyFullName = ({ name, sourceName }: Params) => {
  return sourceName ? `${name}${LABEL_SEPARATOR}${sourceName}` : name;
};

export default getFrequencyFullName;
