import { Typography } from 'antd';
import AntIcon from '@ant-design/icons';
import classNames from 'classnames';
import { FactionIcon } from '@/shared/assets';
import { Tag } from '@/shared/components';
import { LABEL_SEPARATOR } from '@/shared/constants';
import { FactionTagProps } from '@/shared/types';
import styles from './styles.module.css';

const FactionTag = ({ className, label, isLoading = false, isLarge = false, onClose }: FactionTagProps) => {
  const labelParts = label.split(', ');
  const [factionName, parentName] = labelParts;

  return (
    <Tag
      className={classNames(className, { [styles.largeTag]: isLarge })}
      icon={
        <AntIcon
          component={FactionIcon}
          className={styles.icon}
        />
      }
      isLoading={isLoading}
      tooltipContent={labelParts.join(LABEL_SEPARATOR)}
      onClose={onClose}
    >
      <Typography.Text>{factionName}</Typography.Text>
      {parentName && (
        <>
          <Typography.Text type="secondary">{LABEL_SEPARATOR}</Typography.Text>
          <Typography.Text type="secondary">{parentName}</Typography.Text>
        </>
      )}
    </Tag>
  );
};

export default FactionTag;
