import { useEffect, useRef } from 'react';
import { DEFAULT_CLUSTER_TAB } from '@/entities/transcript/constants';
import { addActiveClusterTabs } from '@/entities/transcript/slices';
import { TranscriptItemProps } from '@/entities/transcript/types';
import { useRenderPropsContext } from '@/shared/context';
import { useAppDispatch, useIsMobile, useParams } from '@/shared/hooks';
import { RadioNetworkRouteParams, TranscriptRenderProps } from '@/shared/types';
import { useTranscriptCluster } from './hooks';

interface Params extends TranscriptItemProps {}

const useTranscriptItem = ({ item, activeItemId }: Params) => {
  const { renderSelectionBox, renderTranscriptControls } = useRenderPropsContext<TranscriptRenderProps>();
  const { radioNetworkId } = useParams<RadioNetworkRouteParams>();
  const selectionWrapRef = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();

  const { isCluster, clusterId, clusterMetadata, clusterTranscriptIds, transcript, isTranscriptFetching } =
    useTranscriptCluster({ item });

  const activeNetworkId = transcript.networks[0]?.networkId;
  const hasNetwork = Boolean(activeNetworkId);

  const shouldBeHighlighted = item.id === activeItemId || clusterTranscriptIds.includes(activeItemId);

  const shouldRenderNetworks = Boolean(isCluster ? clusterMetadata?.frequencies.length : transcript.frequencyId);

  useEffect(() => {
    if (shouldBeHighlighted && clusterId) {
      // reset tab when scroll to cluster
      dispatch(addActiveClusterTabs({ [clusterId]: DEFAULT_CLUSTER_TAB }));
    }
  }, [shouldBeHighlighted, clusterId, dispatch]);

  return {
    radioNetworkId,
    selectionWrapRef,
    isMobile,
    isCluster,
    clusterId,
    clusterMetadata,
    transcript,
    isTranscriptFetching,
    activeNetworkId,
    hasNetwork,
    shouldBeHighlighted,
    shouldRenderNetworks,
    renderSelectionBox,
    renderTranscriptControls,
  };
};

export default useTranscriptItem;
