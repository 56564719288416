import { useEffect, useRef, UIEventHandler } from 'react';
import { useDebounceFn, useUnmount } from '@reactuses/core';
import { useGetTranscripts, useTranscriptQueryParams } from '@/entities/transcript/hooks';
import {
  setIsOnTopOfTranscriptList,
  selectIsOnTopOfTranscriptList,
  getEntitySelection,
} from '@/entities/transcript/slices';
import { MAX_REFETCH_INTERVAL, TRANSCRIPTS_QUERY_KEY, DISABLED_REFETCH_SCROLL_TOP } from '@/shared/constants';
import { useScrollUpToReFetch, useAppSelector, useClearInfiniteListAndRefetch, useAppDispatch } from '@/shared/hooks';
import { TranscriptsProps } from '@/shared/types';
import useScrollToTranscriptRow from './useScrollToTranscriptRow';

const SCROLL_NEXT_PAGE_OFFSET = 1500;
const SCROLL_ANIMATION = 300;
let scrollUpToReFetchTimeoutID: NodeJS.Timeout | undefined;

interface Params extends TranscriptsProps {}

const useTranscriptList = ({ networkId, networkType, disableAutoRefetch }: Params) => {
  const { queryParams } = useTranscriptQueryParams({ networkId, networkType });
  const isOnTopOfTranscriptList = useAppSelector(selectIsOnTopOfTranscriptList);
  const { isSelectionDropdownOpen } = useAppSelector(getEntitySelection);
  const listRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const isRefreshTriggerDisabled = !isOnTopOfTranscriptList || isSelectionDropdownOpen;

  const handleClearInfiniteListAndRefetch = useClearInfiniteListAndRefetch([TRANSCRIPTS_QUERY_KEY, queryParams]);

  const { isReFetchingByScrollUp, handleScrollUpToReFetch } = useScrollUpToReFetch([
    TRANSCRIPTS_QUERY_KEY,
    queryParams,
  ]);

  const { transcripts, isFetching, isRefetching, forceFetchNextPage, refetchInterval, setRefetchInterval } =
    useGetTranscripts({
      queryParams,
      shouldAutoRefetch: !disableAutoRefetch,
    });
  const isListLoading = isFetching && !isReFetchingByScrollUp;

  const { activeTranscriptId, scrollToRowIndex } = useScrollToTranscriptRow({ transcripts, isFetching });

  const handleIsOnTopState = (scrollTop: number, scrollHeight: number) => {
    if (scrollTop === 0) {
      dispatch(setIsOnTopOfTranscriptList(true));
    } else if (scrollHeight > window.innerHeight && isOnTopOfTranscriptList) {
      dispatch(setIsOnTopOfTranscriptList(false));
    }
  };

  const { run: handleLoadNextPage } = useDebounceFn((scrollTop: number, scrollHeight: number) => {
    if (scrollHeight - Math.round(scrollTop) < SCROLL_NEXT_PAGE_OFFSET) {
      forceFetchNextPage();
    }
  }, SCROLL_ANIMATION);

  const handleListScroll: UIEventHandler<HTMLDivElement> = (e) => {
    const { scrollTop, scrollHeight } = e.currentTarget;

    handleIsOnTopState(scrollTop, scrollHeight);
    handleLoadNextPage(scrollTop, scrollHeight);

    clearTimeout(scrollUpToReFetchTimeoutID);
    if (isFetching) return;
    if (scrollTop < DISABLED_REFETCH_SCROLL_TOP && !refetchInterval) {
      setRefetchInterval(MAX_REFETCH_INTERVAL);
      scrollUpToReFetchTimeoutID = setTimeout(async () => {
        await handleClearInfiniteListAndRefetch();
      }, MAX_REFETCH_INTERVAL);
    } else if (scrollTop >= DISABLED_REFETCH_SCROLL_TOP && refetchInterval) {
      setRefetchInterval(false);
    }
  };

  useEffect(() => {
    listRef.current?.scrollTo({ top: 0 });
  }, [queryParams, isRefetching]);

  useUnmount(() => dispatch(setIsOnTopOfTranscriptList(true)));

  return {
    transcripts,
    listRef,
    isListLoading,
    handleScrollUpToReFetch,
    handleListScroll,
    isRefreshTriggerDisabled,
    activeTranscriptId,
    scrollToRowIndex,
  };
};

export default useTranscriptList;
