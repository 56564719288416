import { Flex } from 'antd';
import FrequencyName from '@/entities/frequency/components/FrequencyName';
import { FrequencyIcon } from '@/shared/assets';
import { Icon } from '@/shared/components';
import { Frequency } from '@/shared/types';
import styles from './styles.module.css';

const NO_FREQUENCY = 'н/в частота';

const convertFrequenciesToOptions = (frequencies: Pick<Frequency, 'id' | 'value' | 'sourceName'>[]) => {
  return frequencies.map(({ id, value, sourceName }) => {
    const frequencyName = value || NO_FREQUENCY;

    return {
      label: (
        <Flex
          className={styles.wrapper}
          align="center"
          gap={5}
        >
          <Icon component={FrequencyIcon} />
          <FrequencyName
            name={frequencyName}
            sourceName={sourceName}
            withTooltip
          />
        </Flex>
      ),
      value: id,
      frequencyName,
    };
  });
};

export default convertFrequenciesToOptions;
