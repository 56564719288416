import { z } from 'zod';
import {
  networkSchema,
  networkFiltersSchema,
  paginatedWithFoffsetSchema,
  responseDataSchema,
  radioNetworkItemSchema,
  locationSchema,
  baseCategorySchema,
  radioNetworkSchemaV2,
  radioNetworkSchema,
  networkSchemaV2,
  frequencySchema,
  sourceTypeSchema,
} from '@/shared/validationSchemas';

const detailsFactionSchema = z.object({
  id: z.string(),
  name: z.string(),
  shortName: z.string(),
  fullName: z.string(),
  fullShortName: z.string(),
  country: z.string().optional(),
});

const dataStreamParamsSchema = z.object({
  frequencies: z.array(frequencySchema.pick({ id: true, value: true })),
});

export const dataStreamSchema = z.object({
  id: z.string(),
  status: z.enum(['active', 'archived']),
  sourceId: z.number(),
  sourceType: sourceTypeSchema,
  sourceName: z.string(),
  startedAt: z.number().nullable(),
  finishedAt: z.number().nullable(),
  params: dataStreamParamsSchema,
});

export const radioNetworkDetailsSchema = radioNetworkItemSchema.and(
  z.object({
    description: z.string().nullish(),
    details: z.object({
      factions: z.array(detailsFactionSchema),
      locations: z.array(locationSchema),
      categories: z.array(baseCategorySchema),
    }),
    dataStreams: z.array(dataStreamSchema),
  })
);

export const getRadioNetworksFilterSchema = responseDataSchema(networkFiltersSchema);
export const getRadioNetworksSchema = paginatedWithFoffsetSchema(radioNetworkSchemaV2);
export const getRadioNetworkByIdSchema = responseDataSchema(radioNetworkSchema);
export const getRadioNetworkDetailsSchema = responseDataSchema(radioNetworkDetailsSchema);

export const updateDataStreamSchema = responseDataSchema(dataStreamSchema);

export const getNetworksSchema = responseDataSchema(z.array(networkSchemaV2));
export const createNetworkSchema = responseDataSchema(networkSchema);
export const updateNetworkSchema = responseDataSchema(networkSchema);
export const addToNetworkSchema = responseDataSchema(networkSchema);

// TODO Remove this code in 2nd iteration
export const createNetworkSchemaV2 = networkSchemaV2;
export const updateNetworkSchemaV2 = networkSchemaV2;
export const addToNetworkSchemaV2 = networkSchemaV2;
