import { useMemo } from 'react';
import omit from 'lodash/omit';
import { NOT_IN_NETWORK } from '@/entities/transcript/constants';
import { TranscriptNetworksProps } from '@/entities/transcript/types';
import { LABEL_SEPARATOR } from '@/shared/constants';
import { useIsMobile, useParams } from '@/shared/hooks';
import { RadioNetworkRouteParams } from '@/shared/types';
import { EMPTY_NETWORK } from './constants';
import { moveEmptyNetworkToEnd } from './utils';

interface Params extends TranscriptNetworksProps {}

const useTranscriptNetworks = ({ isCluster, clusterMetadata, transcript }: Params) => {
  const { radioNetworkId } = useParams<RadioNetworkRouteParams>();
  const isMobile = useIsMobile();

  const networks = useMemo(() => {
    const clusterNetworks = clusterMetadata?.networks.map((network) => omit(network, ['transcriptIds'])) ?? [];
    const transcriptNetworks = transcript.networks.length > 0 ? transcript.networks : [EMPTY_NETWORK];

    return moveEmptyNetworkToEnd(isCluster ? clusterNetworks : transcriptNetworks);
  }, [isCluster, clusterMetadata?.networks, transcript]);

  const tooltipTitle = networks.map((item) => item.networkName ?? NOT_IN_NETWORK).join(LABEL_SEPARATOR);

  return {
    radioNetworkId,
    isMobile,
    networks,
    tooltipTitle,
  };
};

export default useTranscriptNetworks;
