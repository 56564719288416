import { useState, useMemo, useEffect } from 'react';
import { SelectProps } from 'antd';
import { useGetFrequencies } from '@/entities/frequency/hooks';
import { convertFrequenciesToOptions } from '@/entities/frequency/utils';
import { Select } from '@/shared/components';

interface Props<T> extends Pick<SelectProps, 'placeholder' | 'disabled' | 'labelInValue'> {
  defaultValues?: string[];
  value?: T;
  onChange?: (value: T) => void;
  notInNetworks?: boolean;
}

export const FrequencySelect = <T,>({
  placeholder,
  defaultValues,
  value,
  onChange,
  disabled,
  labelInValue,
  notInNetworks,
}: Props<T>) => {
  const [initialValues, setInitialValues] = useState(defaultValues || []);
  const [inputSearch, setInputSearch] = useState('');

  const { frequencies, isFrequenciesFetching, isInitialLoading } = useGetFrequencies({
    ids: initialValues,
    search: inputSearch,
    notInNetworks,
  });

  const frequenciesOptions = useMemo(() => convertFrequenciesToOptions(frequencies), [frequencies]);

  useEffect(() => {
    if (!isInitialLoading) {
      setInitialValues([]);
    }
  }, [isInitialLoading]);

  return (
    <Select
      mode="multiple"
      placeholder={placeholder}
      options={frequenciesOptions}
      value={value}
      onChange={onChange}
      onSearch={setInputSearch}
      loading={isFrequenciesFetching}
      disabled={disabled}
      labelInValue={labelInValue}
      isAsync
    />
  );
};
