import { PaginatedWithFoffset, request, RequestQueryParams, ResponseData } from '@/shared/api';
import { V2_PREFIX, V3_PREFIX } from '@/shared/config';
import { DEFAULT_PAGE_SIZE } from '@/shared/constants';
import { queryParamsService } from '@/shared/services';
import { Frequency, FrequencyV2 } from '@/shared/types';
import { getFilterFrequenciesSchema, getFrequenciesSchema } from './validationSchemas';

export const getFrequencies = (search: string, notInNetworks: boolean, ids: string[]) => {
  const params = new URLSearchParams();

  if (search) {
    params.set('s', search.toLowerCase());
  }

  queryParamsService.setQueryParamsArrayParameter(params, 'frequency[]', ids);

  if (notInNetworks) {
    params.set('not_in_networks', String(notInNetworks));
  }

  const queryParams = params.toString().length > 0 ? `?${params.toString()}` : '';

  return request<ResponseData<FrequencyV2[]>>(`/radio-networks/frequency${queryParams}`, {
    endpointPrefix: V2_PREFIX,
    validationSchema: getFrequenciesSchema,
  });
};

export const getFilterFrequencies = (queryParams: RequestQueryParams) => {
  return request<PaginatedWithFoffset<Frequency['value']>>(`/transcripts/filter/frequencies`, {
    endpointPrefix: V3_PREFIX,
    validationSchema: getFilterFrequenciesSchema,
    params: {
      size: DEFAULT_PAGE_SIZE,
      ...queryParams,
    },
  });
};
