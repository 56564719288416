import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addToNetworkV2 as addToNetworkApi } from '@/entities/network/api';
import { REBUILDING_CONNECTIONS_IS_IN_PROGRESS } from '@/entities/network/constants';
import { isRadioNetworkLocked } from '@/entities/network/utils';
import {
  ADD_TO_NETWORK_MUTATION_KEY,
  CALL_SIGNS_QUERY_KEY,
  CODES_QUERY_KEY,
  NOTES_QUERY_KEY,
  RADIO_NETWORK_BY_ID_QUERY_KEY,
  RADIO_NETWORK_DETAILS_QUERY_KEY,
  STATUS_TYPES,
  TRANSCRIPTS_QUERY_KEY,
} from '@/shared/constants';
import { useNotificationContext } from '@/shared/context';
import { useClearInfiniteListAndRefetch, useParams } from '@/shared/hooks';
import { Network, RadioNetworkRouteParams } from '@/shared/types';
import { adaptV2ToV3RadioNetwork } from '@/shared/utils';

type Params = {
  onSuccess?: (data: Network) => void;
  onError?: VoidFunction;
};

const FAILED_TO_ADD_FREQUENCY_TO_NETWORK = 'Не вдалось додати частоту до мережі';

export const useAddToNetwork = ({ onSuccess, onError }: Params) => {
  const { radioNetworkId = '' } = useParams<RadioNetworkRouteParams>();
  const handleClearTranscriptsListAndRefetch = useClearInfiniteListAndRefetch([TRANSCRIPTS_QUERY_KEY]);
  const handleClearCallSignsListAndRefetch = useClearInfiniteListAndRefetch([CALL_SIGNS_QUERY_KEY]);
  const handleClearCodesListAndRefetch = useClearInfiniteListAndRefetch([CODES_QUERY_KEY]);
  const handleClearNotesListAndRefetch = useClearInfiniteListAndRefetch([NOTES_QUERY_KEY]);
  const { openNotification } = useNotificationContext();
  const queryClient = useQueryClient();

  const { mutate: addToNetwork, isLoading: isAdding } = useMutation({
    mutationKey: [ADD_TO_NETWORK_MUTATION_KEY],
    mutationFn: addToNetworkApi,
    onSuccess: async (data) => {
      // const updatedNetwork = data.data;
      // TODO Remove this code in 2nd iteration
      const updatedNetwork = adaptV2ToV3RadioNetwork<Network>(data);
      onSuccess?.(updatedNetwork);

      if (radioNetworkId !== updatedNetwork.id) return;

      handleClearTranscriptsListAndRefetch();
      handleClearCallSignsListAndRefetch();
      handleClearCodesListAndRefetch();
      handleClearNotesListAndRefetch();
      await Promise.all([
        queryClient.invalidateQueries([RADIO_NETWORK_BY_ID_QUERY_KEY, updatedNetwork.id]),
        queryClient.invalidateQueries([RADIO_NETWORK_DETAILS_QUERY_KEY, updatedNetwork.id]),
      ]);
    },
    onError: (error) => {
      const isRebuildingInProgress =
        isRadioNetworkLocked({ error, param: 'frequency' }) || isRadioNetworkLocked({ error, param: 'id' });
      const notificationDescription = isRebuildingInProgress
        ? REBUILDING_CONNECTIONS_IS_IN_PROGRESS
        : FAILED_TO_ADD_FREQUENCY_TO_NETWORK;
      openNotification({
        type: 'error',
        title: STATUS_TYPES.ERROR,
        description: notificationDescription,
      });
      onError?.();
    },
  });

  return {
    addToNetwork,
    isAdding,
  };
};
