export const NETWORK = 'Мережа';
export const CREATE_NETWORK = 'Створити мережу';
export const CREATE_NEW_NETWORK = 'Створити нову мережу';
export const NETWORK_NAME = 'Назва мережі';
export const TYPE_NETWORK_NAME = 'Введіть назву мережі';
export const NETWORK_DESCRIPTION = 'Опис мережі';
export const NETWORK_DESCRIPTION_PLACEHOLDER = 'Додайте опис мережі';
export const ADD_TO_NETWORK = 'Додати до мережі';
export const SELECT_NETWORK = 'Оберіть мережу';
export const REBUILDING_CONNECTIONS_IS_IN_PROGRESS =
  'Триває операція перебудови звʼязків в перехопленнях, будь ласка спробуйте повторити операцію через кілька секунд';
